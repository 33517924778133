.me,
h1,
h2 {
  text-align: left;
}
header,
section {
  margin: 0 2rem;
}
section {
  margin-bottom: 2rem;
}
.page {
  grid-template-areas: "header main" "aside main";
  grid-template-columns: 30% 70%;
  grid-template-rows: 0fr 1fr;
  padding: 2rem;
}
.title {
  align-items: flex-start;
}
.title--main {
  align-items: center;
}
.hobby {
  align-items: flex-start;
}
.name {
  text-align: left;
}
